/*=========================================================================================
File Name: ServiceDetails.vue
Description: Service Details Page.
----------------------------------------------------------------------------------------
Item Name: Ekifa Vue || Business and Agency VueJs Template
Version: 1.0.0
Author: Deveable
Copyright 2021
==========================================================================================*/
<template>
<div>
  <!-- Breadcrumb Component -->
  <breadcrumb-area :breadcrumb="breadcrumb"/>
  <!-- Service Details Component -->
  <service-details-area/>
</div>
</template>

<script>
import BreadcrumbArea from "../../components/BreadcrumbArea";
import ServiceDetailsArea from "../../components/ServiceDetailsArea";
export default {
  name: "ServiceDetails",
  components: {ServiceDetailsArea, BreadcrumbArea},
  data() {
    return {
      breadcrumb: {
        page: 'Service Details',
        pageTitle: 'Service Details'
      }
    }
  }
}
</script>

<style scoped>

</style>